import { useState, useEffect } from 'react';
import { Button, TextField, Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import NFTDisplay from './nftdisplay';
import MintNFT from './mintnft';
import desktop_page from '../images/desktop_pages/background_with_logo.png'
import desktop_page_wthout_logo from '../images/desktop_pages/background.png'

import mobile_page_chi from '../images/mobile_image_components/background_chi.png'
import mobile_page_chi_without_logo from '../images/mobile_image_components/background_chi_without_logo.png'
import mobile_page_eng from '../images/mobile_image_components/background_eng.png'
import mobile_page_eng_without_logo from '../images/mobile_image_components/background_eng_without_logo.png'


import AuthCode from 'react-auth-code-input';

import desktop_nft_background from '../images/desktop_image_components/p3_NFT_bg.png';
import mobile_nft_background from '../images/mobile_image_components/p3_NFT_bg_m.png';
import desktop_claim_nft_chi from '../images/desktop_image_components/p1_getyourNFT_chi.png';
import desktop_claim_nft_eng from '../images/desktop_image_components/p1_getyourNFT_eng.png';

import mobile_claim_nft_chi from '../images/mobile_image_components/p1_getyourNFT_chi_m.png';
import mobile_claim_nft_eng from '../images/mobile_image_components/p1_getyourNFT_eng_m.png';

import product from '../images/desktop_image_components/product.png'
import awards from '../images/desktop_image_components/awards.png'
import p1_words_desktop_chi from '../images/desktop_image_components/p1_words_chi.png'
import p1_words_desktop_eng from '../images/desktop_image_components/p1_words_eng.png'
import p2_collectwindow_chi from '../images/desktop_image_components/p2_collectwindow_chi.png'
import p2_collectwindow_eng from '../images/desktop_image_components/p2_collectwindow_eng.png'

import cs_chi from '../images/desktop_image_components/CS_chi.png'
import cs_eng from '../images/desktop_image_components/CS_eng.png'
import connectwallet_chi from '../images/desktop_image_components/p3_walletbtn_chi.png';
import connectwallet_eng from '../images/desktop_image_components/p3_walletbtn_eng.png';


import p3_downloadbtn_chi from '../images/desktop_image_components/p3_downloadbtn_chi.png'
import p3_downloadbtn_eng from '../images/desktop_image_components/p3_downloadbtn_eng.png'

import p2_enterbtn_chi from '../images/desktop_image_components/p2_enterbtn_chi.png'
import p2_enterbtn_eng from '../images/desktop_image_components/p2_enterbtn_eng.png'

import p1_words_mobile_chi from '../images/mobile_image_components/p1_words_chi_m.png'
import p1_words_mobile_eng from '../images/mobile_image_components/p1_words_eng_m.png'

import p3_yourNFT_desktop_chi from '../images/desktop_image_components/p3_yourNFT_chi.png';
import p3_yourNFT_desktop_eng from '../images/desktop_image_components/p3_yourNFT_eng.png';
import p3_yourNFT_mobile_chi from '../images/mobile_image_components/p3_yourNFT_chi_m.png';
import p3_yourNFT_mobile_eng from '../images/mobile_image_components/p3_yourNFT_eng_m.png';

import desktop_p3_words_chi from '../images/desktop_image_components/p3_words_chi.png';
import desktop_p3_words_eng from '../images/desktop_image_components/p3_words_eng.png';
import mobile_p3_words_chi from '../images/mobile_image_components/p3_words_chi_m.png';
import mobile_p3_words_eng from '../images/mobile_image_components/p3_words_eng_m.png';

import desktop_enquiry_chi from '../images/desktop_image_components/enquiry_chi.png';
import desktop_enquiry_eng from '../images/desktop_image_components/enquiry_eng.png';
import mobile_enquiry_chi from '../images/mobile_image_components/enquiry_chi.png';
import mobile_enquiry_eng from '../images/mobile_image_components/enquiry_eng.png';

import desktop_lang_chi from '../images/desktop_image_components/lang_chi.png'
import desktop_lang_eng from '../images/desktop_image_components/lang_eng.png'
import mobile_lang_chi from '../images/mobile_image_components/lang_chi_m.png'
import mobile_lang_eng from '../images/mobile_image_components/lang_eng_m.png'

import mobile_lang_path from '../images/mobile_image_components/lang_path_m.png';
import desktop_lang_path from '../images/desktop_image_components/lang_path.png'
import hashedcode from '../hashedcode';
import { sha256, sha224 } from 'js-sha256';
import { blue } from '@mui/material/colors';
import loadingimg from '../images/loading.png'
//create ClaimCoupon component
const ClaimCoupon = (props) => {
    const [nftMeta, setNftMeta] = useState(null);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [currentRatio, setCurrentRatio] = useState(0.5);
    const [mobileDeterminedSide, setMobileDeterminedSide] = useState('h');
    const [desktopDeterminedSide, setDesktopDeterminedSide] = useState('h');
    const [isChi, setIsChi] = useState(false);
    const [page, setPage] = useState(1);
    const [couponCode, setCouponCode] = useState('123456');
    const [nftCode, setNftCode] = useState(0);
    const [showConnectWallet, setShowConnectWallet] = useState(true);
    const desktopImageWidth = 4000;
    const desktopImageHeight = 2250;

    const mobileImageWidth = 751;
    const mobileImageHeight = 1334;
    const comingSoon = false;

    const getServerPath = (endpoint) => {
        let origin = window.location.origin.replace(":3000", "");
        let url = origin + endpoint;
        console.log("url:" + url);

        return url;
    }
    function getWindowSize() {
        // const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        // const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;

        // let width = window.innerWidth;
        // let height = window.innerHeight;
        // let pixelRatio = window.devicePixelRatio;
        let pixelRatio = 1;
        // console.log(`window width:${width} height: ${height} pixelRatio ${pixelRatio}`)
        return { width: width / pixelRatio, height: height / pixelRatio };
    }
    useEffect(() => {

    }, []);
    useEffect(() => {
        function handleWindowResize() {
            let wSizes = getWindowSize();
            setWindowSize(getWindowSize());
            setCurrentRatio(wSizes.width / wSizes.height);
            if (wSizes.width / wSizes.height > (mobileImageWidth / mobileImageHeight)) {
                setMobileDeterminedSide('h');
            } else {
                setMobileDeterminedSide('w');
            }
            if (wSizes.width / wSizes.height > (desktopImageWidth / desktopImageHeight)) {
                setDesktopDeterminedSide('h');
            } else {
                setDesktopDeterminedSide('w');
            }
        }
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const getNFTMetaData = async (couponCode) => {
        let tokenResponse = axios.get(getServerPath("/getMetaData") + `?code=${couponCode}`).then(response => {
            let tokenMeta = response.data;
            setNftMeta(tokenMeta);
        }).catch(err => {
            alert("Invalid coupon code.");
        })
    }
    const mint = (walletAddress) => {
        console.log(`minting ${walletAddress} code ${couponCode}`);
        axios.get(getServerPath("/mint?address=" + walletAddress + "&code=" + couponCode))
            .then(res => {
            })
    }
    const desktopImageStyle = () => {
        let wSizes = getWindowSize();
        let currentRatio = wSizes.width / wSizes.height;

        if (currentRatio > (desktopImageWidth / desktopImageHeight)) {
            return { width: '100%' }
        } else {
            return { height: '100%' }
        }
    }
    const mobileImageStyle = () => {
        let wSizes = getWindowSize();
        //potentially some error here, intented to swap the width and height
        let currentRatio = wSizes.width / wSizes.height;

        if (currentRatio > (mobileImageWidth / mobileImageHeight)) {
            return { width: '100%' }
        } else {
            return { height: '100%' }
        }
    }
    const getMobileDivStyle = () => {
        if (mobileDeterminedSide == 'h') {
            return { position: 'relative', backgroundColor: 'gray', height: '100%', width: (windowSize.height / mobileImageHeight) * mobileImageWidth }
        } else {
            return { position: 'relative', backgroundColor: 'gray', width: '100%', height: (windowSize.width / mobileImageWidth) * mobileImageHeight }
        }
    }
    const getDesktopDivStyle = () => {
        if (desktopDeterminedSide == 'h') {
            return { position: 'relative', backgroundColor: 'gray', height: '100%', width: (windowSize.height / desktopImageHeight) * desktopImageWidth }
        } else {
            return { position: 'relative', backgroundColor: 'gray', width: '100%', height: (windowSize.width / desktopImageWidth) * desktopImageHeight }
        }
    }
    const goToMainPage=() => {
        setCouponCode("");
        setPage(1);

    }
    const desktopCommonComponents = [
        { type: "img", key: 'lang_chi', img: desktop_lang_chi, top: 4.5, left: 93.8, width: 4, onClick: () => setIsChi(true) ,  cursor:'pointer'},
        { type: "img", key: 'lang_sep', img: desktop_lang_path, top: 8.3, left: 93, width: 6 ,  cursor:'pointer', onClick:()=>setIsChi(!isChi)},
        { type: "img", key: 'lang_eng', img: desktop_lang_eng, top: 14, left: 93.3, width: 5, onClick: () => setIsChi(false) , cursor:'pointer'},
        { type: 'img', key:'enquiry', img: isChi?desktop_enquiry_chi:desktop_enquiry_eng, top:93.3, left:0, width:100, cursor:'pointer'},
        { type: 'blank', key:'mainbar', top:0, left:0, width:92, height:21, cursor:'pointer', onClick:()=>goToMainPage()},
        { type: 'blank', key:'emailbar', top:93, left:0, width:79, height:7, cursor:'pointer', onClick:()=>window.open("mailto:enquiry@lumispaionft.com", "_blank")},
        { type: 'blank', key:'terms', top:93, left:80, width:9, height:7, cursor:'pointer', onClick:()=>window.open("terms_of_use.pdf", "_blank")},       
        { type: 'blank', key:'privacy', top:93, left:90, width:9, height:7, cursor:'pointer', onClick:()=>window.open("privacy_policy.pdf", "_blank")},       
        
    ]
    const mobileCommonComponents = [
        { type: "img", key: 'lang_chi', img: mobile_lang_chi, top: 4.5, left: 55, width: 8, onClick: () => setIsChi(true) ,  cursor:'pointer'},
        { type: "img", key: 'lang_sep', img: mobile_lang_path, top: 4.0, left: 68, width: 0.3 ,  cursor:'auto', cursor:'pointer', onClick:()=>setIsChi(!isChi)},
        { type: "img", key: 'lang_eng', img: mobile_lang_eng, top: 4.4, left: 73, width: 10, onClick: () => setIsChi(false) , cursor:'pointer'},
        { type: 'blank', key:'mainbar', top:0, left:0, width:45, height:11, cursor:'pointer', onClick:()=>goToMainPage()},
        { type: 'blank', key:'emailbar', top:91, left:0, width:100, height:4.5, cursor:'pointer', onClick:()=>window.open("mailto:enquiry@lumispaionft.com", "_blank")},
        { type: 'blank', key:'terms', top:95, left:36, width:17, height:4.8, cursor:'pointer', onClick:()=>window.open("terms_of_use.pdf", "_blank")},       
        { type: 'blank', key:'privacy', top:95, left:55, width:17, height:4.8, cursor:'pointer', onClick:()=>window.open("privacy_policy.pdf", "_blank")},       
        
        
        // { type: 'img', key:'enquiry', img: isChi?mobile_enquiry_chi:mobile_enquiry_eng, top:91, left:0, width:100, cursor:'pointer'},
    ]
    const deskTextTop = 49;
    const convertCodeToNFTNo=(code)=> {
        console.log('Coupon code ' + code);
        let hashed = sha256(code);
        return hashedcode[hashed];
    }
    const goToPage3 = () => {
        let nftCode = convertCodeToNFTNo(couponCode.toLocaleLowerCase());
        if(nftCode==undefined) {
            alert(isChi?"NFT 碼不正確" + couponCode:"Coupon code Invalid");
        }else{
            setNftCode(nftCode);
            setPage(3);
        }
        console.log(nftCode);
    }
    const imagePath = `https://bafybeieekapjfh3bri24ducqaepfv4tbij7glrahijpn2rh3ptoeiqm6te.ipfs.dweb.link/tier2_500/%230${nftCode}.png`;
    const debugClickArea = false;

    const downloadImg=()=> {
        window.open(imagePath, '_blank').focus();
    }
    const pageImages = {
        d_1: {
            background: desktop_page,
            components: [
                { type: "img", key: 'preload', img: desktop_page_wthout_logo, top: 0, left: 0, width: 0, cursor:'pointer'},
                { type: "img", key: 'claimNFTBtn', img: isChi ? desktop_claim_nft_chi : desktop_claim_nft_eng, top: 39, left: 74, width: 23, onClick: () => setPage(2) , cursor:'pointer'},
                { type: "img", key: 'product', img: product, top: 28, left: 5, width: 13, cursor:'pointer', onClick:()=>window.open('https://www.nuskin.com/content/nuskin/zh_HK/product-info/lumispa-io-info.html', '_blank').focus()},
                { type: "img", key: 'awards', img: awards, top: 58, left: 5, width: 12 , cursor:'auto'},
                { type: "img", key: 'words', img: isChi ? p1_words_desktop_chi : p1_words_desktop_eng, top: 37, left: 22, width: 35 , cursor:'auto'},

                ...desktopCommonComponents
            ]
        },
        d_2: {
            background: desktop_page,
            components: [
                { type: "img", key: 'claimBox', img: isChi ? p2_collectwindow_chi : p2_collectwindow_eng, top: 34, left: 30, width: 40 ,  cursor:'auto'},
                { type: "img", key: 'enterBtn', img: isChi ? p2_enterbtn_chi : p2_enterbtn_eng, top: 38, left: 65, width: 15, onClick: () => goToPage3() ,  cursor:'pointer'},
                { type: "codeInput", key: "code1", top: deskTextTop, left: 30 , width: 30, height: 30,  cursor:'auto' },
                ...desktopCommonComponents
            ]
        },
        d_3: {
            background: desktop_page_wthout_logo,
            components: [
                { type: "img", key: 'nftbackground', img:desktop_nft_background, top: 26, left: 15, width: 70 , cursor:'auto'},
                { type: "img", key: 'yournft', img:isChi?p3_yourNFT_desktop_chi:p3_yourNFT_desktop_eng, top: 43, left: 17, width: 6, cursor:'auto' },
                { type: "img", key: 'downloadnow', img:isChi?p3_downloadbtn_chi:p3_downloadbtn_eng, top: 32, left: 68, width: 12, cursor:'pointer', onClick:()=> downloadImg()},
                // { type: "img", key: 'connectwallet', img:isChi?(showConnectWallet?connectwallet_chi:cs_chi):(showConnectWallet?connectwallet_eng:cs_eng), top: 50, left:60, width: 12, cursor:'pointer', onMouseOver:()=>setShowConnectWallet(false),  onMouseOut:()=>setShowConnectWallet(true)},
                { type: "img", key: 'connectwallet', img:isChi?connectwallet_chi:connectwallet_eng, top: 50, left:60, width: 12, cursor:'pointer', onClick:()=>connectWallet()},
            
                { type: "img", key: 'p3_words', img:isChi?desktop_p3_words_chi:desktop_p3_words_eng, top: 80, left: 12, width: 76 , cursor:'auto'},
                { type: "img", key:'loadingimg', img: loadingimg, 
                top: 26, left: 26.5, width: 27.76 , cursor:'auto'},
                { type: "img", key:'nftimg', img: imagePath, 
                        top: 26, left: 26.5, width: 27.76 , cursor:'auto'},
                ...desktopCommonComponents
            ]
        },
        m_1: {
            background: isChi?mobile_page_chi:mobile_page_eng,
            components: [
                { type: "img", key: 'preloadchi', img: mobile_page_chi, top: 0, left: 0, width: 0, cursor:'pointer'},
                { type: "img", key: 'preloadeng', img: mobile_page_eng, top: 0, left: 0, width: 0, cursor:'pointer'},
                { type: "img", key: 'claimNFTBtn', img: isChi ? mobile_claim_nft_chi : mobile_claim_nft_eng, top: 50, left: 67, width: 30, onClick: () => setPage(2) , cursor:'pointer'},
                { type: "img", key: 'awards', img: awards, top: 48, left: 5, width: 26 , cursor:'auto'},
                { type: "img", key: 'product', img: product, top: 24, left: 4, width: 35, cursor:'pointer', onClick:()=>window.open('https://www.nuskin.com/content/nuskin/zh_HK/product-info/lumispa-io-info.html', '_blank').focus()},
                { type: "img", key: 'words', img: isChi ? p1_words_mobile_chi : p1_words_mobile_eng, top: 31, left: 41, width: 55 , cursor:'auto'},
                ...mobileCommonComponents
            ]
        },
        m_2: {
            background: isChi?mobile_page_chi:mobile_page_eng,
            components: [
                { type: "img", key: 'claimBox', img: isChi ? p2_collectwindow_chi : p2_collectwindow_eng, top: 28, left: 5, width: 90 ,  cursor:'auto'},
                { type: "img", key: 'enterBtn', img: isChi ? p2_enterbtn_chi : p2_enterbtn_eng, top: 50, left: 67, width: 28, onClick: () => goToPage3() ,  cursor:'pointer'},
                { type: "codeInput", key: "code1", top: deskTextTop, top:37, left: 3, width: 80, height: 30,  cursor:'auto' },
                ...mobileCommonComponents
            ]
        },
        m_3: {
            background: isChi?mobile_page_chi_without_logo:mobile_page_eng_without_logo,
            components: [
                { type: "img", key: 'nftbackground', img:mobile_nft_background, top: 26, left: 5, width: 90 , cursor:'auto'},
                { type: "img", key: 'yournft', img:isChi?p3_yourNFT_mobile_chi:p3_yourNFT_mobile_eng, top: 27.5, left: 7, width: 25, cursor:'auto' },
                { type: "img", key: 'downloadnow', img:isChi?p3_downloadbtn_chi:p3_downloadbtn_eng, top: 33, left: 66, width: 24, cursor:'pointer', onClick:()=> downloadImg()},
                // { type: "img", key: 'connectwallet', img:isChi?(showConnectWallet?connectwallet_chi:cs_chi):(showConnectWallet?connectwallet_eng:cs_eng), top: 47, left: 66, width: 24,cursor:'pointer', onMouseOver:()=>setShowConnectWallet(false),  onMouseOut:()=>setShowConnectWallet(true)},
                { type: "img", key: 'connectwallet', img:isChi?connectwallet_chi:connectwallet_eng, top: 47, left: 66, width: 24,cursor:'pointer', onClick:()=>connectWallet()},
                { type: "img", key: 'p3_words', img:isChi?mobile_p3_words_chi:mobile_p3_words_eng, top: 69, left: 15, width: 70 , cursor:'auto'},
                { type: "img", key:'loadingimg', img: loadingimg, top: 31, left: 7, width: 56, cursor:'auto'},
                { type: "img", key:'nftimg', img: imagePath, top: 31, left: 7, width: 56 , cursor:'auto'},
                ...mobileCommonComponents
            ]
        },
    }
    const connectWallet = async() => {
        var walletaddress = window.prompt(isChi?"請輸入錢包地址":"Please enter your wallet address");
        var ethformatmatch = walletaddress.match(/^0x[a-fA-F0-9]{40}$/g);
        if(ethformatmatch!=null) {
            try {
                let result = await axios.post("https://t6ff45853h.execute-api.ap-east-1.amazonaws.com/Prod/checkcode",  {"couponCode":couponCode, "address":ethformatmatch});
                alert(isChi?"NFT 成功存入錢包":"NFT minted into wallet");
            }catch (error) {
                alert((isChi?"NFT 存入出現錯誤, 請重試":"Error minting NFT into wallet, please try again") + " error:" + error);
            }
        }else{
            alert(isChi?"錢包地址不正碓, 請重試":"Wallet address incorrect, please try again");
        }
    }
    const couponCodeOnChange = (e) => {
        console.log(e);
        setCouponCode(e.toLocaleLowerCase());
    }
    return (<>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: "0vh", width: '100vw', height: '100vh', backgroundColor: 'gray', position: 'relative' }}>
            <div style={getDesktopDivStyle()}>
                <img src={pageImages[`d_${page}`]['background']} style={desktopImageStyle()}></img>
                {pageImages[`d_${page}`]['components'].map((c, cidx) => {
                    if (c['type'] == 'img') return <div key={c['key']} style={{ top: `${c['top']}%`, left: `${c['left']}%`, width: `${c['width']}%`, position: 'absolute', cursor:c['cursor']}}><img src={c['img']} style={{ width: '100%' }} onClick={c['onClick']} onMouseOver={c['onMouseOver']} onMouseOut={c['onMouseOut']} /></div>
                    if (c['type'] == 'codeInput') return <div key={c['key']} style={{ position: 'absolute', top: `${c['top']}%`, left: `${c['left']}%`, height: `${c['height']}%`, width: `${c['width']}%` }} >
                        <AuthCode type='text' length={6} value={couponCode} onChange={e => couponCodeOnChange(e)} containerClassName='container' inputClassName='input' />
                    </div>
                    if(c['type'] == 'blank') return <div key={c['key']} style={{position:'absolute', backgroundColor:debugClickArea?'blue':null,  top: `${c['top']}%`, left: `${c['left']}%`, height: `${c['height']}%`, width: `${c['width']}%`, cursor:c['cursor']}} onClick={c['onClick']} ></div>
                    else return null;
                }
                )}
            </div>
        </Box>
        <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: "0vh", width: '100vw', height: '100vh', backgroundColor: 'gray', position: 'relative' }}>
            <div style={getMobileDivStyle()}>
                <img src={pageImages[`m_${page}`]['background']} style={mobileImageStyle()}></img>
                {pageImages[`m_${page}`]['components'].map((c, cidx) => {
                    if (c['type'] == 'img') return <div key={c['key']} style={{ top: `${c['top']}%`, left: `${c['left']}%`, width: `${c['width']}%`, position: 'absolute', cursor:c['cursor']}}><img src={c['img']} style={{ width: '100%' }} onClick={c['onClick']} onMouseOver={c['onMouseOver']} onMouseOut={c['onMouseOut']}/></div>
                    if (c['type'] == 'codeInput') return <div key={c['key']} style={{ position: 'absolute', top: `${c['top']}%`, left: `${c['left']}%`, height: `${c['height']}%`, width: `${c['width']}%` }} >
                        <AuthCode type='text' length={6} value={couponCode} onChange={e => couponCodeOnChange(e)} containerClassName='container' inputClassName='input' />
                    </div>
                    if(c['type'] == 'blank') return <div key={c['key']} style={{position:'absolute', backgroundColor:debugClickArea?'blue':null,  top: `${c['top']}%`, left: `${c['left']}%`, height: `${c['height']}%`, width: `${c['width']}%`, cursor:c['cursor']}} onClick={c['onClick']} ></div>
                    else return null;
                }
                )}
            </div>
        </Box>

        {/* <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: 'grey' }}>
            <div style={getMobileDivStyle()}>
                <img src={mobilePage1} style={mobileImageStyle()} ></img>
                <div style={{ top: '50%', left: '68%', width: '30%', position: 'absolute' }}>
                    <img src={mobile_claim_nft_chi} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '30%', left: '5%', width: '25%', position: 'absolute' }}>
                    <img src={product} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '50%', left: '5%', width: '25%', position: 'absolute' }}>
                    <img src={awards} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '32%', left: '32%', width: '55%', position: 'absolute' }}>
                    <img src={p1_words_mobile_chi} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '5%', left: '70%', width: '8%', position: 'absolute' }}>
                    <img src={lang_chi} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '4.5%', left: '81.5%', width: '0.3%', position: 'absolute' }}>
                    <img src={mobile_lang_path} style={{ width: '100%' }} />
                </div>
                <div style={{ top: '5%', left: '85%', width: '10%', position: 'absolute' }}>
                    <img src={lang_eng} style={{ width: '100%' }} />
                </div>
            </div>
        </Box> */}
    </>

    );
};
export default ClaimCoupon;




// https://raw.githubusercontent.com/ximplarandy/NuSkinMeta/main/data/1.png